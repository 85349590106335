import {types} from "mobx-state-tree";
import {reaction} from "mobx";
import {DialogManager} from "../ContentControl/DialogManager";
import {minuteMilliseconds} from "../lib/DateQuirks";

export const LockStore = types
    .model("LockStore", {
        locked: types.optional(types.boolean, true),
        ttl: types.optional(types.integer, 9999)
    })
    .actions((self) => ({
        setLocked: function (value: boolean = !self.locked) {
            const modalRoot = new DialogManager().getRootNode();

            self.locked = value;
            if (modalRoot && value) {
                // Sperren
                modalRoot.style.display = "none";
            } else if (modalRoot) {
                // entsperren
                modalRoot.style.display = "";
            }
        },
        setTtl: function (value: number) {
            self.ttl = value / 60;
        },
        decreaseTtl: function () {
            self.ttl--;
        }
    }));

export const Lock = LockStore.create({});

setInterval(() => {
    Lock.decreaseTtl();
}, minuteMilliseconds);

reaction(
    () => Lock.ttl,
    (ttl) => {
        if (ttl <= 0) {
            Lock.setLocked(true);
        }
    }
);
