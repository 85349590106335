import {createRoot, Root} from "react-dom/client";
import {WorkingOverlay} from "./react/WorkingOverlay";
import {Lock} from "./GlobalStores/Lock";
import {initProfile, isTouchBased, setProfileToStorage} from "./GlobalStores/ProfileFromStorage";

document.addEventListener("keydown", (event) => {
    if (event.code == "PageUp" || event.code == "PageDown") {
        event.preventDefault();
    }
});

const appNode = document.createElement("div");
document.body.append(appNode);
const root: Root = createRoot(appNode);

function getCleanAppNode(): Root {
    return root;
}

function renderWorkingOverlay() {
    getCleanAppNode().render(<WorkingOverlay working={true} />);
}

export async function main() {
    console.warn("main START");
    renderWorkingOverlay();
    console.warn("main WORKING OVERLAY DONE");

    const urlParams = new URLSearchParams(window.location.search);

    initProfile();
    if (urlParams.has("profile")) {
        const profile = urlParams.get("profile");
        if (profile) {
            setProfileToStorage(profile);
        }
    }

    const {loadColors} = await import("./GlobalStores/Theme");
    loadColors();

    // if (urlParams.has("ccs")) {
    //     await launchCcs();
    // } else {
    const {initUser} = await import("./GlobalStores/User");
    if (await initUser()) {
        Lock.setLocked(false);

        const {initDeviceConfig} = await import("./GlobalStores/DeviceConfig");
        initDeviceConfig();

        const {initUserConfig} = await import("./GlobalStores/UserConfig");
        initUserConfig();

        const {loadMessages} = await import("./functions/loadMessages");
        loadMessages();

        const {loadMarkers} = await import("./functions/loadMarkers");
        loadMarkers();

        if (isTouchBased()) {
            const {run} = await import("./initializer/mobile");
            return run(getCleanAppNode);
        } else {
            const {run} = await import("./initializer/run");
            return run(getCleanAppNode);
        }
    }

    // ApiCaller hat Fehler 401 von Server bekommen. Lock.locked muss also wieder auf 'false' zurückgestellt werden.
    // Sonst wird der Zugang anfragen Dialog nicht angezeigt
    Lock.setLocked(false);
    const {login} = await import("./initializer/login");
    login(getCleanAppNode);
    // }

    return true;
}

main().catch(console.error);
