import MobileDetect from "mobile-detect";

export function getProfileFromStorage(): string {
    return localStorage.getItem("profile") || "root";
}

export function setProfileToStorage(value: string | undefined) {
    if (value === undefined) {
        return localStorage.removeItem("profile");
    }

    localStorage.setItem("profile", value);
}

export function isTouchBased(): boolean {
    const profile = getProfileFromStorage();
    return profile === "smartphone" || profile === "tablet";
}

export function isSmartphone(): boolean {
    const profile = getProfileFromStorage();
    return profile === "smartphone";
}

export function isTablet(): boolean {
    const profile = getProfileFromStorage();
    return profile === "tablet";
}

export function initProfile() {
    const local = localStorage.getItem("profile");
    if (local) {
        setProfileToStorage(local);
    } else {
        const md = new MobileDetect(window.navigator.userAgent);

        const isPhone = !!md.phone();
        if (isPhone) {
            setProfileToStorage("smartphone");
        }
        const isTablet = !!md.tablet();
        if (isTablet) {
            setProfileToStorage("tablet");
        }
    }
}
