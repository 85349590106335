import {
    Dispatch,
    memo,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
    ReactNode,
    CSSProperties
} from "react";
import * as styles from "./WorkingOverlay.module.less";
import {LoaderImg} from "./Svg/LoaderImg/LoaderImg";
import {WorkingStateContext} from "./Tab/Lib/WorkingStateContext";

interface IProps {
    working: boolean;
    children?: ReactNode;
    style?: CSSProperties;
}

export const WorkingOverlay = memo(function WorkingOverlay(props: IProps) {
    const [working, setWorking] = useState(props.working);
    const ref = useRef<[boolean, Dispatch<SetStateAction<boolean>>]>([working, setWorking]);

    useEffect(() => {
        ref.current[0] = working;
    }, [working]);

    useEffect(() => {
        setWorking(props.working);
    }, [props.working]);

    let containerStyle = "";
    let grayscaleStyle = "";

    if (working) {
        containerStyle = styles.container;
        grayscaleStyle = styles.grayscale;
    }

    const handleDoubleClick = useCallback(() => setWorking(false), []);

    return (
        <WorkingStateContext.Provider value={ref}>
            <div className={containerStyle} style={{minHeight: 0, height: "100%", ...props.style}}>
                {props.children}
            </div>
            {working ? (
                <div className={grayscaleStyle}>
                    <LoaderImg size={150} onDoubleClick={handleDoubleClick} />
                </div>
            ) : undefined}
        </WorkingStateContext.Provider>
    );
});
