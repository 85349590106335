import {LangCategory, LangCategoryContent} from "../@types/i18next";
import {LangColumnEntry} from "../react/Table/Store/TableStore";
import tableNameTranslate from "../../../../system/lang/tableNameTranslate.json";
import {PHPW} from "../generated.ts";
import ArticleLanguage = PHPW.Entities.Enum.ArticleLanguage;

export type TOptions = {[key: string]: string | number | readonly string[] | undefined} | undefined;

export type TCatContent<NS extends LangCategory> = (key: LangCategoryContent<NS>, options?: TOptions) => string;

export function getLangFromStorage(): ArticleLanguage {
    const value = localStorage.getItem("lang") || undefined;

    switch (value) {
        case "de":
        case "german": {
            return ArticleLanguage.DE;
        }
        case "en":
        case "english": {
            return ArticleLanguage.EN;
        }
        default: {
            if (navigator.language.startsWith("de")) {
                return ArticleLanguage.DE;
            }
            if (navigator.language.startsWith("en")) {
                return ArticleLanguage.EN;
            }
            return ArticleLanguage.DE;
        }
    }
}

export function setLangToStorage(value: string | undefined) {
    if (value === undefined) {
        return localStorage.removeItem("lang");
    }
    return localStorage.setItem("lang", value);
}

export function keyPrefix<P extends string | number, T extends string | number>(prefix: P, key: T): `${P}${T}` {
    return "".concat(String(prefix), String(key)) as `${P}${T}`;
}

export type Data = {
    [key: string]: string;
};

export type TableData = {
    [key: string]: LangColumnEntry;
};

type importReturn = {default: unknown};

export async function fetchTableData(lang: string, nameSpace: string) {
    const namespace = (tableNameTranslate as {[key: string]: string})[nameSpace] || nameSpace;
    try {
        return ((await import(`../../../../system/lang/${lang}/tables/${namespace}.json`)) as importReturn)
            .default as TableData;
    } catch {
        return ((await import(`../../../../system/lang/de/tables/${namespace}.json`)) as importReturn)
            .default as TableData;
    }
}

async function fetchData(lang: ArticleLanguage, nameSpace: string) {
    try {
        return ((await import(`../../../../system/lang/${lang}/${nameSpace}.json`)) as importReturn).default as Data;
    } catch {
        return ((await import(`../../../../system/lang/de/${nameSpace}.json`)) as importReturn).default as Data;
    }
}

async function fetchCommon(lang: ArticleLanguage) {
    try {
        return ((await import(`../../../../system/lang/${lang}/common.json`)) as importReturn).default as Data;
    } catch {
        return ((await import(`../../../../system/lang/de/common.json`)) as importReturn).default as Data;
    }
}

export function fetchAllData(lang: ArticleLanguage, nameSpace: string): Promise<[Data, Data]> {
    return Promise.all([fetchData(lang, nameSpace), fetchCommon(lang)]);
}

export function unpack(data: Data, common: Data, key: string, options?: TOptions): string {
    const value = data[key] || common[key] || "MISSING:" + key;
    if (options) {
        return Array.from(Object.entries(options || {})).reduce<string>((acc, [key, value]) => {
            return acc.replaceAll(`{{${key}}}`, String(value));
        }, value);
    }

    return value;
}

export function unpackTable(data: TableData, key: string): LangColumnEntry {
    return data[key] || {long: "MISSING:" + key};
}

export async function translate<NS extends LangCategory>(nameSpace: NS): Promise<TCatContent<NS>> {
    const lang = getLangFromStorage();
    const [data, common] = await fetchAllData(lang, nameSpace);
    return function (key: LangCategoryContent<NS>, options?: TOptions) {
        return unpack(data, common, key, options);
    };
}
