import {MessageStore} from "../react/MailQueue/Store/MessageStore";
import {MessageQueueInstance} from "../react/MailQueue/Store/MessageQueueStore";

const handleMarkReadEvent = function (event: Event) {
    const customEvent = event as CustomEvent<{messageId: string}>;
    const {messageId} = customEvent.detail;
    MessageQueueInstance.removeMessageCenterElementByIdent(messageId);
};

document.addEventListener("markReadEvent", handleMarkReadEvent as EventListener);

function notify(
    message: string,
    type?: "info" | "success" | "error" | "warning" | "chat",
    callback?: () => void,
    options?: {timestamp?: Date; messageIdent?: string}
): void {
    if (!new Set(["info", "success", "error", "warning", "chat"]).has(type as string)) {
        type = "info";
    }

    const element = new MessageStore(
        type || "info",
        message,
        undefined,
        options?.timestamp,
        callback,
        options?.messageIdent
    );
    MessageQueueInstance.addElement(element);
}

function message(message: string, callback?: () => void): void {
    return notify(message, "info", callback);
}

function success(message: string, callback?: () => void): void {
    return notify(message, "success", callback);
}

function error(message: string, callback?: () => void): void {
    return notify(message, "error", callback);
}

function warning(message: string, callback?: () => void): void {
    return notify(message, "warning", callback);
}

export const alertify = {
    notify: notify,
    message: message,
    success: success,
    error: error,
    warning: warning
};
