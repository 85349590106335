import {memo} from "react";
import {InlineSvg, ValidIconSize} from "../InlineSvg";
import * as styles from "./LoaderImg.module.less";

interface IProps {
    className?: string;
    onClick?(): void;
    onDoubleClick?(): void;
}

export const LoaderImg = memo(function LoaderImg(props: IProps & {size: ValidIconSize}) {
    return <InlineSvg {...props} className={[styles.loading, props.className].join(" ")} name={"progress_activity"} />;
});
